import { z } from 'zod';

export const ContextSchema = z
  .object({
    contractType: z.number().optional(),
    contractStatus: z.number().optional(),
    trialPeriodDuration: z.number().optional(),
    cddDuration: z.number().optional(),
    tenurable: z.string(),
    positionMotiveId: z.number().optional(),
    recruitmentReason: z.string().max(4000),
    recruitmentOpenPositionDuration: z.number().optional(),
    difficulties: z.string(),
    openPositionDurationUnit: z.string().nullable().optional(),
  })
  .refine(
    ({ contractType, trialPeriodDuration }) => {
      if (contractType !== 4) return true;
      if (trialPeriodDuration === undefined) return false;
      return trialPeriodDuration >= 0 && trialPeriodDuration <= 1;
    },
    {
      message: `Doit être entre 0 et 1`,
      path: ['trialPeriodDuration'],
    }
  )
  .refine(
    ({ contractType, trialPeriodDuration }) => {
      if (contractType !== 4) return true;
      if (trialPeriodDuration === undefined) return false;
      return trialPeriodDuration >= 0 && trialPeriodDuration <= 8;
    },
    {
      message: `Doit être entre 0 et 8`,
      path: ['trialPeriodDuration'],
    }
  )
  .refine(
    ({ contractType, cddDuration }) => {
      if (contractType !== 4) return true;
      if (cddDuration === undefined) return false;
      return cddDuration >= 0 && cddDuration <= 72;
    },
    {
      message: `Doit être entre 0 et 72 mois`,
      path: ['cddDuration'],
    }
  );
